<template>
  <div>
    <show-code :code="fieldInfoList"></show-code>
    <show-code :code="preResult"></show-code>
    <load-block :load="!load"></load-block>
    <the-edit-page
      :scheme="scheme"
      :isEntity="true"
      :loadData="loadData"
      v-if="load"
      :title="title"
      :pageMode="pageMode"
    >
      <the-controls-edit-page
        :scheme="scheme"
        :loadData="loadData"
        :saveData="saveData"
        :pathBack="pathBack"
        :wait="wait"
        configName="agent"
        :roleName="nameRoleEntity"
        :pageMode="pageMode"
        :show-history="!($route.name == routeNameCreate)"
        :open-history="openHistory"
         :hasHistory="false"
      ></the-controls-edit-page>
      <template name="block2">
        <company-data
          :isCodeBudg="false"
          :isCodePers="false"
          :isPerson="false"
          :loadDataIn="loadData"
          :isAgent="true"
          :activeShowMenu="true"
        ></company-data>
      </template>
    </the-edit-page>
    <HistoryCompany
      v-if="!($route.name == routeNameCreate)"
      :modals="modals"
      :toggle-modal="toggleModal"
      :closeHistory="closeHistory"
      configName="agent"
    />
  </div>
</template>
<script>
import scheme from "../scheme.json";
import TheEditPage from "../../../TheEditPage.vue";
import { ENTITY_NAME, GET_LIST_ENTITY, SET_TAB_CLIENT } from "../store/type";
import { READ, UPDATE } from "../../../../store/type";
import TheControlsEditPage from "../../../TheControlsEditPage.vue";
import clearReactive from "../../../../helpers/clearReactive";
import ShowCode from "../../../shared/ShowCode.vue";
import convertFieldInfoListToScheme from "../../../../helpers/converters/convetFieldInfoListToScheme";
import clearNull from "../../../../helpers/clearNull";
import { mapState } from "vuex";

import { TYPE_ERRORS, FIELD_LANG_COMPANY } from "../../../../constant";
import notify from "../../../../helpers/notify/notify";
import CompanyData from "../CompanyData.vue";
import LoadBlock from "../../../shared/LoadBlock.vue";
import { checkValidate } from "../../../../mixins/validate/checkValidate";
import { pageMode } from "../../../../mixins/editPage/pageMode";
import HistoryCompany from "../../history/HistoryCompany";
import {NAME_OBJECT} from "../../../../RoleConstanans"
export default {
  name: "AgentEditPageDynamic",
  components: {
    LoadBlock,
    CompanyData,
    ShowCode,
    TheControlsEditPage,
    TheEditPage,
    HistoryCompany
  },
  mixins: [checkValidate, pageMode],
  data() {
    return {
      wait: false,
      pathBack: "/company/agent",
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,
      routeNameShow: "AgentShow",
      routeNameCreate: "AgentCreate",
      modals: {
        history: false
      }
    };
  },
  computed: {
    ...mapState({
      lang: state => state.common.lang
    }),
    nameRoleEntity(){
      return NAME_OBJECT.agent
    },
    title() {
      return this.pageTitle(
        this.$t("Просмотр агентства"),
        this.$t("Создание агентства"),
        this.$t("Редактирование агентства")
      );
    }
  },
  watch: {
    lang() {
      this.initData();
    }
  },
  created() {
    this.initData();
  },
  methods: {
    toggleModal(name) {
      this.modals[name] = !this.modals[name];
    },
    openHistory() {
      this.toggleModal("history");
    },
    closeHistory() {
      this.toggleModal("history");
      this.initData();
    },
    initData() {
      // this.$store.commit(SET_TAB_CLIENT, 'contract');
      this.load = false;
      this.$store
        .dispatch(ENTITY_NAME + READ, {
          id: this.$route.params.CompanyId || "0",
          self: this,
          typeCompany: "Agency"
        })
        .then(res => {
          this.loadData = res.object;
          let fieldInfoList = res.fieldInfoList;
          this.fieldInfoList = fieldInfoList; //del in future
          this.scheme.configForm = convertFieldInfoListToScheme({
            fieldInfoList,
            langFieldName: FIELD_LANG_COMPANY
          });
          this.preResult = convertFieldInfoListToScheme({
            fieldInfoList,
            langFieldName: "companyLangDTOList"
          }); //del in future
          this.load = true;
        });
    },
    saveData() {
      if (this.validateData() == false) {
        return;
      }
      let saveData = clearReactive(this.loadData);
      saveData = clearNull(saveData);
      this.wait = true;
      this.$store
        .dispatch(ENTITY_NAME + UPDATE, {
          id: this.$route.params.CompanyId,
          saveData: saveData,
          self: this
        })
        .then(res => {
          this.wait = false;
          if (res.errors.length == 0) {
            notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
            this.$router.push({ path: this.pathBack });
          }
        })
        .catch(err => {
          console.error(err);
          this.wait = false;
        });
    }
  }
};
</script>
